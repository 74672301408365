* {
    box-sizing: border-box;
}

.Card {
    position: relative;
    min-width: 10rem;
    max-width: 10rem;
    min-height: 13rem;
    background-color: white;
    border: 2px solid black;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
}

.card-back {
    background-image: url(./red2.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.top-left {
    position: absolute;
    top: 1.5rem;
    left: .30rem;
}

.card-value {
    font-size: 3rem;
    height: fit-content;
    line-height: 0;
}

.card-suit {
    font-size: 4rem;
    height: fit-content;
    line-height: 5rem;
}

.big-suit {
    font-size: 9rem;
    text-align: center;
    margin-top: 5rem;
    line-height: 100%;
}

/*.Card::before {
    position: absolute;
    content: attr(data-value);
    font-size: 3rem;
}

.Card::before {
    left: .5rem;
}*/

.black {
    color: black;
}

.red {
    color: red;
}