.App {
  font-family: sans-serif;
}

.paytable {
  display: flex;
  justify-content: center;
}

table {
  background-color: darkblue;
  color: yellow;
  border-collapse: collapse;
  border: 1px solid yellow;
  margin-bottom: 50px;
}

td {
  text-align: right;
  border-left: 1px solid yellow;
  padding-left: 25px;
  padding-right: 5px;
  padding-bottom: 5px;
}

tr {
  padding-top: 15px;
  padding-bottom: 15px;
}

.payTableType {
  text-align: left;
  border-right: 1px solid yellow;
  padding-left: 5px;
  padding-right: 15px;
}

.winning-hand {
  color: white;
}

.cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.deal, .draw {
  text-align: center;
  margin-top: 3rem;
}

.deal-button, .draw-button {
  box-sizing: border-box;
  background-color: yellow;
  border: 2px solid black;
  border-radius: .25rem;
  font-size: 2.5rem;
  cursor: pointer;
}

.deal-button:active, .draw-button:active {
  background-color: #dddd00;
}