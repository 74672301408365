.Hold {
    background-color: yellow;
    border: 3px solid red;
    border-radius: .5rem;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    width: fit-content;
    margin: auto;
    padding: .25rem;
    margin-bottom: .25rem;
}

.hidden {
    visibility: hidden;
}